<!-- list -->
<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <search-inner :search-form="searchForm" @submit-search="search" @clear-search="reset">
        <el-col :span="6">
          <el-form-item label="模型键值：">
            <el-input v-model="searchForm.modelKey" placeholder="请输入模型键值" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="模型名称：">
            <el-input v-model="searchForm.modelName" placeholder="请输入模型名称" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="类别：">
            <el-select v-model="searchForm.category" placeholder="请选择要搜索的类别" filterable>
              <el-option label="全部" value="" />
              <el-option v-for="item in constants.modelType" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
      </search-inner>
    </div>
    <div class="partition-table">
      <el-radio-group v-model="selectWorkflow" class="table-radio-group">
        <table-inner :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" @changePageNum="changePageNum">
          <template slot="btn-inner">
            <el-button :loading="loading.btn" type="primary" @click="dialog.add = true">
              新增
            </el-button>
            <el-button :loading="loading.btn" :disabled="!selectWorkflow.id" type="primary" @click="deploy">
              部署
            </el-button>
            <el-button :loading="loading.btn" :disabled="!selectWorkflow.id || selectWorkflow.deployId" type="primary" @click="remove">
              删除
            </el-button>
            <el-button :loading="loading.btn" :disabled="!selectWorkflow.id" type="primary" @click="exportFile">
              导出
            </el-button>
          </template>
          <template slot="table-columns">
            <el-table-column width="60" label="选择" align="center">
              <template slot-scope="scope">
                <el-radio :label="scope.row" />
              </template>
            </el-table-column>
            <el-table-column label="序号" type="index" align="center" show-overflow-tooltip min-width="100" />
            <el-table-column prop="deployId" label="部署ID" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="key" label="模型键值" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="name" label="模型名称" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="category" label="类别" :formatter="(row, col, cell) => this.utils.statusFormat(String(cell), 'modelType')" show-overflow-tooltip min-width="150" />
            <el-table-column prop="productName" label="金融产品" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
            <el-table-column prop="version" label="模型版本" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
            <el-table-column prop="createTime" label="创建时间" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="lastUpdateTime" label="最后更新时间" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="meteInfo" label="元数据" :formatter="utils.isEffective" min-width="150" />
            <el-table-column label="操作" align="center" fixed="right" min-width="120">
              <template slot-scope="scope">
                <router-link class="text-btn" :to="{ name: 'systemWorkflowEdit', query: { id: scope.row.id } }">
                  编辑
                </router-link>
                <router-link v-if="scope.row.deployId && !['other', 'access'].includes(scope.row.category)" class="text-btn" :to="{ name: 'systemWorkflowConfig', query: { id: scope.row.id, productCode: scope.row.productCode } }">
                  配置
                </router-link>
              </template>
            </el-table-column>
          </template>
        </table-inner>
      </el-radio-group>
    </div>
    <el-dialog :visible.sync="dialog.add" :close-on-click-modal="false" class="form-dialog" title="新增模型" @close="closeDialog">
      <div class="form-area">
        <div class="form-inner">
          <el-form ref="workflowForm" label-position="right" label-width="110px" :model="workflowModel" class="" :rules="rules">
            <el-form-item prop="modelName" label="名称：">
              <el-input v-model="workflowModel.modelName" />
            </el-form-item>
            <el-form-item v-if="isShowModelKey" prop="modelKey" label="KEY：">
              <el-input v-model="workflowModel.modelKey" />
            </el-form-item>
            <el-form-item prop="category" label="类别：">
              <el-radio-group v-model="workflowModel.category" @change="categoryChange">
                <el-radio v-for="item in constants.modelType" :key="item.value" :label="item.value">
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="['credit', 'use'].includes(workflowModel.category)" prop="productCode" label="关联金融产品：">
              <el-select v-model="workflowModel.productCode" placeholder="请选择金融产品" filterable @change="productChange">
                <el-option v-for="item in productList" :key="item.productCode" :label="`${item.productName}（${item.productCode}）`" :value="item.productCode" />
              </el-select>
            </el-form-item>
            <el-form-item prop="description" label="描述：">
              <el-input v-model="workflowModel.modelDescription" type="textarea" :rows="3" />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog.add = false">
          取 消
        </el-button>
        <el-button type="primary" :loading="loading.submit" @click="addWorkflowModal">
          保存
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>
<script>
export default {
  components: {},
  data () {
    return {
      searchForm: {
        modelId: '',
        modelKey: '',
        modelName: '',
        category: ''
      },
      selectWorkflow: {},
      tableList: {
        list: []
      },
      productList: [],
      isShowModelKey: false,
      workflowModel: {
        modelKey: '',
        modelName: '',
        category: '',
        productCode: '',
        productName: '',
        modelDescription: ''
      },
      loading: {
        list: false,
        btn: false,
        submit: false
      },
      dialog: {
        add: false
      },
      rules: {
        modelName: [
          { required: true, message: '请输入模型名称', trigger: 'blur' }
        ],
        modelKey: [
          { required: true, message: '请输入模型Key', trigger: 'blur' },
          { validator: this.validateModelKey, trigger: 'blur' }
        ],
        category: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        productCode: [
          { required: false, message: '请选择关联产品', trigger: 'change' }
        ],
      }
    }
  },
  watch: {},
  created () {
    this.recoverSearch()

  },
  methods: {
    validateModelKey (rule, value, callback) {
      if (/^[a-zA-Z][a-zA-Z0-9]*$/g.test(value)) {
        callback()
      } else {
        callback(new Error('请输入以英文字母开头，只能包含英文字母、数字'))
      }
    },
    addWorkflowModal () {
      this.$refs.workflowForm.validate().then(() => {
        this.loading.submit = true
        this.api.workflow.addWorkFlow(this.workflowModel).then(result => {
          this.$message.success(result.data.errorCode)
          this.dialog.add = false
          this.search()
        }).finally(() => {
          this.loading.submit = false
        })
      })
    },
    deploy () {
      this.checkSelect()
      this.$confirm('确认要部署该模型吗？', '部署').then(() => {
        this.loading.btn = true
        this.api.workflow.deployById(this.selectWorkflow.id).then(result => {
          this.$message.success(result.data.errorCode)
          this.search()
        }).finally(() => {
          this.loading.btn = false
        })
      })
    },
    remove () {
      this.checkSelect()
      this.$confirm('确认要删除该模型吗？', '删除').then(() => {
        this.loading.btn = true
        this.api.workflow.deleteById(this.selectWorkflow.id).then(result => {
          this.$message.success(result.data.errorCode)
          this.search()
        }).finally(() => {
          this.loading.btn = false
        })
      })
    },
    exportFile () {
      this.checkSelect()
      this.loading.btn = true
      this.api.workflow.flowExpor(this.selectWorkflow.id).then(result => this.utils.exportFile(result, null, { jumpTitleMatch: true })).finally(() => {
        this.loading.btn = false
      })
    },
    checkSelect () {
      if (!this.selectWorkflow.id) {
        this.$message.error('请选择要操作的工作流')
        return false
      }
    },
    closeDialog () {
      this.workflowModel.modelName = ''
      this.workflowModel.modelKey = ''
      this.workflowModel.modelDescription = ''
      this.$refs.workflowForm.resetFields()
    },
    recoverSearch () {
      this.searchForm = Object.assign(this.searchForm, this.$route.query)
      this.getList()
    },
    search () {
      this.searchForm.pageNum = 1
      this.$router.push({ query: this.searchForm })
      this.getList()
    },
    reset () {
      this.searchForm = {
        modelId: '',
        modelKey: '',
        modelName: '',
        productName: '',
        category: ''
      }
      this.$router.push({ query: this.searchForm })
      this.getList()
    },
    getList () {
      this.loading.list = true
      this.api.workflow.listPage(this.searchForm).then(result => {
        this.tableList = result.data.data
        this.selectWorkflow = {}
      }).finally(() => {
        this.loading.list = false
      })
    },
    changePageNum ({ pageNum }) {
      this.searchForm.pageNum = pageNum
      this.getList()
    },
    categoryChange (val) {
      this.workflowModel.productCode = ''
      this.workflowModel.productName = ''
      if (['credit', 'use'].includes(val)) {
        this.rules.productCode[0].required = true
        this.isShowModelKey = false
        this.getProductList(val)
      } else if (val === 'access') {
        this.isShowModelKey = false
      } else {
        this.isShowModelKey = true
        this.rules.productCode[0].required = false
      }
    },
    getProductList (val) {
      const data = { category: val }
      this.api.workflow.unboundList(data).then(result => {
        this.productList = result.data.data || []
      }).finally(() => {
      })
    },
    productChange (val) {
      const data = this._.find(this.productList, { productCode: val }) || {}
      const { productName } = data
      this.workflowModel = this._.assign(this.workflowModel, { productName })
    }
  }
}
</script>
<style lang="less"></style>
